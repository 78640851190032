<template>
  <v-main>
    <v-carousel
      cycle
      dark
      hide-delimiter-background
      show-arrows-on-hover
      height="300">
      <v-carousel-item
        v-for="(item,i) in imageLists"
        :key="i"
        :src="windowSize.x > 1000 ? item.img_url : item.img_url_1000"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-col
            class="text-center"
            cols="8"
          >
            <div class="text-h4">
              {{item.caption}}
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <v-container class="my-12">
        <v-row class="bl_campaign">
          <v-col cols="12">
            <h4 style="text-align:center;">新しいココロミ、はじめました。</h4>
          </v-col>
          <v-col cols="12" class="bl_campaign_inner">
            <router-link to="/class-index" class="el_index_campaignBanner">
              <div class="el_index_campaignBanner_wrapper">
                <span class="hp_textNowrap el_index_campaignBanner_span1">Adobe Frescoで始める</span>
                <span class="hp_textNowrap el_index_campaignBanner_span2">デジタルお絵描きワークショップ</span>
              </div>
              <img src="/img/common/digitalOekakiWorkshop_00_2x.png" />
            </router-link>
          </v-col>
        </v-row>
    </v-container>

    <v-container class="bl_news">
      <v-row justify="start" align="stretch">
        <v-col v-for="article in itemCount" :key="article.date"
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 px-8 py-6"  height="100%">
            <div class="d-flex justify-space-between mb-0">
              <span class="text-caption">{{ new Date(article.date).toLocaleDateString() }}</span>
              <div :class="'el_'+article.cathegory" class="bl_news_category"></div>
            </div>
            <v-card-title class="px-0 py-2 text-h7">{{ article.title }}</v-card-title>

            <v-card-text class="text--primary pa-0">
              <div class="summary" v-html="article.bodytext"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import sanitizeHtml from "sanitize-html";

export default {
  name: 'Home',
  components: {

  },
  data(){
    return{
      windowSize: {
        x: 0,
        y: 0,
      },
      imageLists: [
        {
          img_url:'/img/common/calousel/bl_calousel_bl_calousel_1_2000.png',
          img_url_1000:'/img/common/calousel/bl_calousel_bl_calousel_1_1000.png',
          caption: '何かと何かの間でパチリと光る',
        },
        {
          img_url:'/img/common/calousel/bl_calousel_bl_calousel_2_2000.png',
          img_url_1000:'/img/common/calousel/bl_calousel_bl_calousel_2_1000.png',
          caption: '世の中に楽しいこと面白いことを',
        },
        {
          img_url:'/img/common/calousel/bl_calousel_bl_calousel_3_2000.png',
          img_url_1000:'/img/common/calousel/bl_calousel_bl_calousel_3_1000.png',
          caption: '好奇心を可視化する',
        },
        {
          img_url:'/img/common/calousel/bl_calousel_bl_calousel_4_2000.png',
          img_url_1000:'/img/common/calousel/bl_calousel_bl_calousel_4_1000.png',
          caption: 'デザインで橋をかける',
        },
      ],
      articles: [],
    }
  },
  computed: {
    itemCount() {
      return this.articles.slice(0,3)
    },
    sanitizedBody() {
      // imgタグのみ使えるようにする
      return sanitizeHtml(this.article.bodytext, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"])
      });
    }
  },

  async mounted() {
    //ウインドウサイズを取得する
    this.onResize()

    // 記事を取得する
    const response = await axios.get(
      "https://interemitcms.microcms.io/api/v1/news",
      {
        headers: { "X-API-KEY": '53b77b5c-566a-4d9e-9c01-deb5cf1a83ac' }
      }
    );
    this.articles = response.data.contents;
  },
  methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    },
}
</script>

<style scoped>
.bl_container__default{
  display: flex;
  column-gap: 16px;
  row-gap: 32px;
  margin: 16px auto;
  padding: 16px;
}
.bl_cardListWrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100vw;
  column-gap: 16px;
  row-gap: 32px;
}
.bl_imageCard{
  max-width: 30vw;
}
.bl_campaign{
  display: flex;
  justify-content: center;
}
.bl_campaign_inner{
  display: flex;
  justify-content: center;
}
.el_index_campaignBanner{
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 32px;
  width:fit-content;
  padding: 16px 32px;
  min-width: 300px;
  border-radius: 8px;
  background: linear-gradient(135deg, #eea2a2 0%, #eea2a2 20%, #bbc1bf 30%, #57c6e1 60%, #b49fda 85%, #7ac5d8 100%) fixed;
  background-size: 200% 200%;
  color: white;
  animation-name: gradation;
  animation-duration: 10.0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes gradation{
    0%{
        background-position: 100% 100%;
    }
    100%{
        background-position: 0% 0%;
    }
}

.el_index_campaignBanner_wrapper{
  display: flex;
  flex-direction: column;
}
.el_index_campaignBanner .el_index_campaignBanner_span1,
.el_index_campaignBanner .el_index_campaignBanner_span2{
  font-size: 22px;
  font-weight: 600;
}
.el_index_campaignBanner img{
  max-width: 200px;
}
.bl_news{
  padding: 32px 0;
}
.bl_news dl{
  display: flex;
  flex-wrap: wrap;
}
.bl_news dt{
  width: 8em;
  color: #999;
}
.bl_news dd{
  display: inline-flex;
  max-width: calc(100% - 10em);
  min-width: 350px;
}
.bl_news_category:after{
  content: "etc";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #14b3e3;
  font-weight: bolder;
  width: 7em;
  height: 1.25em;
}
.el_works:after{
  content: "works";
}
.el_illustration:after{
  content: "illust";
}
.el_etc:after{
  content: "etc";
}
</style>
